import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './text';

export const Heading1 = ({ children, color }) => (
  <Text as="h1" size="4xl" weight="extrabold" className={`${color}`}>
    {children}
  </Text>
);
Heading1.propTypes = { children: PropTypes.node.isRequired, color: PropTypes.string };
Heading1.defaultProps = { color: 'text-green-500' };

export const Heading2 = ({ children, color }) => (
  <Text as="h2" size="3xl" weight="extrabold" className={color}>
    {children}
  </Text>
);
Heading2.propTypes = { children: PropTypes.node.isRequired, color: PropTypes.string };
Heading2.defaultProps = { color: 'text-green-500' };

export const Heading3 = ({ children, color }) => (
  <Text as="h3" size="2xl" weight="bold" className={color}>
    {children}
  </Text>
);
Heading3.propTypes = { children: PropTypes.node.isRequired, color: PropTypes.string };
Heading3.defaultProps = { color: 'text-gray-900' };

export const Heading4 = ({ children, color }) => (
  <Text as="h4" size="xl" weight="semibold" className={color}>
    {children}
  </Text>
);
Heading4.propTypes = { children: PropTypes.node.isRequired, color: PropTypes.string };
Heading4.defaultProps = { color: 'text-tertiary-800' };
