import React from 'react';
import Layout from '../components/layout/layout';
import joelleImg from '../images/partners/joelle.png';
import equipmentScolaireImg from '../images/partners/equipement_scolaire.png';
import equipmentLoisirImg from '../images/partners/equipement_loisir.png';
import materielInformatiqueImg from '../images/partners/materiel_informatique.png';
import materielMedicalImg from '../images/partners/materiel-medical.jpg';
import lePersonelImg from '../images/partners/le-personnel.jpg';
import inondationImg from '../images/partners/inondation.jpg';
import saintJulienImg from '../images/partners/saint-julien.png';
import zoumbaImg from '../images/partners/compagnie_zoumba.png';
import { Container } from '../components/container';
import { Heading1, Heading2 } from '../components/headings';
import { Text } from '../components/text';
import { Section } from '../components/section';
import * as PropTypes from 'prop-types';

const needs = [
  {
    title: 'Rémunération du personnel',
    image: {
      src: lePersonelImg,
      alt: 'Bénévoles',
    },
    body: `Le personnel travaille bénévolement. Un appui financier pour leur rémunération serait le bienvenu.`,
  },
  {
    title: 'Outils informatiques',
    image: {
      src: materielInformatiqueImg,
      alt: 'Outils informatiques',
    },
    body: `Ordinateurs, imprimantes...`,
  },
  {
    title: 'Entretien et rénovation',
    image: {
      src: inondationImg,
      alt: 'Entretien et rénovation',
    },
    body: `Carrelage et badigeonnage des murs pour la protection contre la salinité des lieux et les inondations.`,
  },
  {
    title: 'Équipements scolaires',
    image: {
      src: equipmentScolaireImg,
      alt: 'Équipements scolaires',
    },
    body: `Craie, cahiers, stylos, tables, bancs...`,
  },
  {
    title: 'Équipements de loisir',
    image: {
      src: equipmentLoisirImg,
      alt: 'Équipements de loisir',
    },
    body: `Toboggans et balançoires feraient la joie des enfants.`,
  },
  {
    title: `Matériels d'urgence médicale`,
    image: {
      src: materielMedicalImg,
      alt: `Matériels d'urgence médicale`,
    },
    body: `Trousses de secours, Armoires à pharmacie.`,
  },
];
const partners = [
  {
    title: 'Solidarité Saint-Julien - Saint-Louis ',
    image: {
      src: saintJulienImg,
      alt: 'Solidarité Saint-Julien - Saint-Louis ',
    },
    body: `Appui à la rénovation de MERERUE et aux repas des enfants.`,
  },
  {
    title: 'Joelle Hautbois et son époux Michel',
    image: {
      src: joelleImg,
      alt: 'Joelle Hautbois',
    },
    body: `Organisation de missions d’encadrement du personnel et d’aide de MERERUE.`,
  },
  {
    title: 'Compagnie Zoumba',
    image: {
      src: zoumbaImg,
      alt: 'Compagnie Zoumba',
    },
    body: `Sorties et animations.`,
  },
];
const previousPartners = [
  {
    title: 'Minnesota Studies International',
    image: {
      alt: 'Minnesota Studies International',
    },
    body: `Envoi de stagiaires à MERERUE.`,
  },
  {
    title: `École Boisseau - Clichy.`,
    image: {
      alt: 'École Boisseau - Clichy.',
    },
    body: `Correspondances des enfants avec leurs camarades français.`,
  },
  {
    title: `BDL (Bibliothèque, Lecture, Développement)`,
    image: {
      alt: `BDL (Bibliothèque, Lecture, Développement)`,
    },
    body: `Formation du staff et des formatrices en gestion de bibliothèque.`,
  },
  {
    title: `Ambassade des USA`,
    image: {
      alt: `Ambassade des USA`,
    },
    body: `Entremise d’un projet a offert le carrelage de la grande salle.`,
  },
  {
    title: 'Projet Amour de Ottawa',
    image: {
      alt: 'Projet Amour de Ottawa',
    },
    body: `Envoi de stagiaires à MERERUE.`,
  },
  {
    title: `Centre Culturel Africain`,
    image: {
      alt: `Centre Culturel Africain.`,
    },
    body: `Fabrication de poupées de chiffon.`,
  },
  {
    title: `Fondation Clé du Paradis`,
    image: {
      alt: `Fondation Clé du Paradis`,
    },
    body: `Ndogou du coeur.`,
  },
  {
    title: `L’Association française Brigades vertes`,
    image: {
      alt: `L’Association française Brigades vertes`,
    },
    body: `plantation d’arbres (badamier, Palmier et raisins de mer).`,
  },
  {
    title: `Fondation Paul Gérin Lajoie`,
    image: {
      alt: `Fondation Paul Gérin Lajoie`,
    },
    body: `Encadrement du personnel.`,
  },
];

const CardWithBottomImage = ({ body, image, title }) => (
  <div className="bg-white overflow-hidden shadow rounded-lg flex flex-col h-full space-y-4">
    <div className="flex-grow px-4 sm:px-6 lg:px-8 py-4 space-y-2">
      <Text as="h3" size="xl" weight="bold" className="card-title">
        {title}
      </Text>
      <p>{body}</p>
    </div>
    {image.src ? <img src={image.src} alt={image.alt} /> : ''}
  </div>
);

CardWithBottomImage.propTypes = { need: PropTypes.any };
const PartnersPage = () => (
  <Layout title="Nos Partenaires">
    <Section>
      <Container maxWidth="4xl" className="px-4 sm:px-8 text-center space-y-4">
        <Heading1>Nos partenaires</Heading1>
        <Text as="p" size="lg">
          MERERUE lance un appel à tous ceux qui désirent l’appuyer dans son programme soit financièrement, matériellement ou en parrainant
          un enfant. Pour pérenniser les activités en faveur des enfants, MERERUE préconise le parrainage. En donnant 15 euros par mois,
          vous contribuerez largement à l’éducation, l’alimentation et le suivi médical d’un enfant.
        </Text>
      </Container>
    </Section>

    <Section className="text-lg">
      <Container maxWidth="7xl" className="space-y-8">
        <section className="space-y-4">
          <Heading2>Nos besoins immédiats</Heading2>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {needs.map((need, index) => (
              <CardWithBottomImage key={index} {...need} />
            ))}
          </div>
        </section>

        <section className="space-y-4">
          <Heading2>Nos partenaires actuels</Heading2>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {partners.map((partner, index) => (
              <CardWithBottomImage key={index} {...partner} />
            ))}
          </div>
        </section>

        <section className="space-y-4">
          <Heading2>Nos précédents partenaires</Heading2>
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {previousPartners.map((partner, index) => (
              <CardWithBottomImage key={index} {...partner} />
            ))}
          </div>
        </section>
      </Container>
    </Section>
  </Layout>
);

export default PartnersPage;
